import './ForgedInIsolation.scss';
import { useEffect, useRef } from 'react';
import lp1 from '../assets/ForgedInIsolation/lp-1-01.svg'
import lp2 from '../assets/ForgedInIsolation/lp-2-01.svg'
import lp3 from '../assets/ForgedInIsolation/lp-3-01.svg'
import lp4 from '../assets/ForgedInIsolation/lp-4-01.svg'
import lp5 from '../assets/ForgedInIsolation/lp-5-01.svg'
import lp6 from '../assets/ForgedInIsolation/lp-6-01.svg'
import lp7 from '../assets/ForgedInIsolation/lp-7-01.svg'
import lp8 from '../assets/ForgedInIsolation/lp-8-01.svg'
import lp9 from '../assets/ForgedInIsolation/lp-9-01.svg'
import lp10 from '../assets/ForgedInIsolation/lp-10-01.svg'
import lp11 from '../assets/ForgedInIsolation/lp-11-01.svg'

function ForgedInIsolation() {

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    const parallaxRef = useRef();

    const ref = useRef()

    return (
        <div className='fi-wrapper'>
            <div className='ceiling'></div>
        </div>
    );
}

export default ForgedInIsolation;